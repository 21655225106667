import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/SEO";
import { Contact } from "../components/Contact";

const EnglishContactPage: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Contact" lang="en" pathName={location.pathname} />
    <Contact language="en" />
  </>
);

export default EnglishContactPage;
